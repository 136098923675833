.error {
  color: rgb(236, 33, 33);
}

.errorBorder {
  border-color: rgb(236, 33, 33);
}

.form_control {
  width: 100%;
  background: transparent;
  padding: 5px 0px;
  border-bottom: #817c7c solid 1px;
  margin-top: 15px;
  margin-bottom: 25px;
  font-family: Poppins;
  position: relative;
}
.form_control input {
  width: 100%;
  /* background: transparent; */
  padding: 0px;
  border: none;
  font-family: Poppins;
  outline: none;
  color: #817c7c;
  font-size: 16px;
}
