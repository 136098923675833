.home_banner_bg {
  position: relative;
}
.home_banner {
  padding: 100px 50px 70px 100px;
  display: flex;
  position: relative;
}
.home_banner > div {
  flex: 1;
}
.home_banner .banner_text_section h1 {
  color: #fff;
  font-family: "Quicksand";
  font-size: var(--hero-text);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.home_banner .banner_text_section h1 span {
  color: inherit;
  z-index: 10;

  position: relative;
}
.home_banner .banner_text_section h1 span img {
  position: absolute;
  width: 205px;
  top: 0;
  z-index: -8;

  left: -15px;
}

.home_banner .banner_text_section p {
  color: #fff;
  font-family: "Quicksand";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 153.846% */
}
.banner_btn {
  height: 180px;
  padding-top: 60px;
}
.banner_btn button:last-child {
  display: none;
}
.banner_img_section {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: url(/images/banner_img.png); */
  background-size: contain;
  background-repeat: no-repeat;
}
.banner_trio {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
.trio_item {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.trio_item p {
  color: #fff;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 24px !important;
}
.banner_img_section {
  position: relative;
}
.banner_img_section div {
  position: absolute;
  z-index: 10;
}
.banner_img_section .spares_btn {
  top: 220px;
  left: 80px;
}
.banner_img_section .services_btn {
  top: 200px;
  right: 20%;
}
.banner_img_section .consultant_btn {
  bottom: 2%;
  right: 40%;
}
.agent_btn {
  bottom: 4%;
  right: 8%;
  position: absolute;
  z-index: 10;
}
.agent_btn button {
  padding: 1rem 4.2rem;
  font-family: "Poppins";
}
.banner_btn a {
  background: #db2a6b;
  padding: 1rem 4.2rem;
  font-family: "Poppins";
  border-radius: 15px;
  color: white;
}
.plane_decoration,
.content_decoration {
  position: absolute;

  z-index: 1;
}
.plane_decoration {
  bottom: -16%;
  right: 30%;
  width: 300px;
}
.content_decoration {
  bottom: -46%;
  right: 0%;
  width: 700px;
}
.globe {
  /* background-image: url(/images/decoration.png); */
  background-repeat: no-repeat;
  background-size: 700px;
  background-position: 80% 25%;
  padding: 100px 70px;
}
.globe_text_section {
  display: flex;
}
.globe_text_section > div {
  flex: 1;
}
.globe_text h1 {
  color: #db2a6b;
  font-family: "Quicksand";
  font-size: var(--hero-text);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.globe_text p {
  color: var(--main, #db2a6b);
  font-family: "Quicksand";
  font-size: 20px;
  font-style: normal;
  padding-top: 10px;
  font-weight: 400;
  line-height: 40px; /* 153.846% */
}
.globe_desc svg {
  width: 28px;
}
.globe_desc {
  position: relative;
}
.globe_desc button {
  padding: 0.5rem 1.2rem;
  /* width: 200px; */
  column-gap: 10px;
  display: flex;
  background: #fff;
  position: absolute;
  font-size: 16px;
  border-radius: 16px;
  z-index: 10;
}
.globe_desc button:nth-child(1) {
  position: absolute;
  top: 40px;
  left: 20%;
}
.globe_desc button:nth-child(2) {
  position: absolute;
  top: 20px;
  right: 30%;
}
.globe_desc button:nth-child(3) {
  position: absolute;
  bottom: 20px;
  left: 20%;
}
.globe_desc button:nth-child(4) {
  position: absolute;
  bottom: 0px;
  right: 30%;
}
.globe_delivery_section {
  padding: 50px 0px;
}
.delivery_tab {
  border-radius: 40px 0px 0px 0px;
  /* box-shadow: -2px 1px 4px -2px rgba(0, 0, 0, 0.69); */
}
.delivery_tab button {
  background: rgba(171, 171, 171, 0.15);
  display: flex;
  display: inline-flex;
  width: 170.033px;
  padding: 12px;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  border-radius: 0px;
}
.delivery_tab button:first-child {
  border-radius: 40px 0px 0px 0px;
  background-color: #fff;
  box-shadow: -2px 0px 2px 0px rgba(0, 0, 0, 0.1);
}
.delivery_tab_pannels {
  display: inline-flex;
  padding: 32px 64px;
  align-items: center;
  gap: 40px;
  border-radius: 0px 40px 40px 40px;
  background: #fff;
  box-shadow: -2px 3px 2px 0px rgba(0, 0, 0, 0.1);
}
.delivery_tab_pannel {
  display: flex;
  column-gap: 108px;
  align-items: center;
  min-height: 70px;
}
.delivery_tab_pannel_divider {
  width: 1px;
  height: 40px;
  background-color: #ababab;
}
.delivery_tab_pannel > div h6 {
  color: #ababab;

  /* Text/Text 2 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}
.delivery_tab_pannel > div:not(:last-child) button {
  color: #2e2e2e;

  /* Text/Text Bold 2 */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  column-gap: 12px;
  display: flex;
}
.delivery_tab_pannel > div button svg:first-child path {
  color: #db2a6b;
}
.blockchain_section {
  /* background: url(/images/blockchain_bg.png); */
  display: flex;
  background-size: cover;
  align-items: center;
  padding: 100px 50px 70px 100px;
}
.blockchain_section > div {
  flex: 1;
}
.blockchain_text_section h1 {
  color: #fff;
  font-family: "Quicksand";
  font-size: var(--hero-text);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.blockchain_text_section p {
  color: #fff;
  font-family: "Quicksand";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 153.846% */
}
.partner_images {
  display: flex;
  column-gap: 10px;
  padding: 50px 0px;
}
.partner_images img {
  width: 80px;
}
.faq_section {
  padding: 100px 50px 70px 100px;
}
.faq_section h1 {
  color: #0c0d12;
  font-family: Quicksand;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
  font-variant: all-small-caps;
  letter-spacing: 0.96px;
}
.faq_btn {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-direction: column;
  flex: 0.7;
}
.faq_flex {
  display: flex;
  align-items: start;
  column-gap: 100px;
  padding: 50px 0px;
}
.faq_flex > div:not(:first-child) {
  flex: 1;
}
.faq {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
@media screen and (max-width: 1530px) {
  .banner_img_section .spares_btn {
    top: 170px;
    left: 60px;
  }
  .banner_img_section .services_btn {
    top: 170px;
    right: 20px;
  }
  .banner_img_section .consultant_btn {
    bottom: 34%;
    right: 160px;
  }
  .globe_desc button:nth-child(1) {
    position: absolute;
    top: 40px;
    left: 20px;
  }
  .globe_desc button:nth-child(2) {
    position: absolute;
    top: 20px;
    right: 20%;
  }
  .globe_desc button:nth-child(3) {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  .globe_desc button:nth-child(4) {
    position: absolute;
    bottom: 60px;
    right: 25%;
  }
}
@media screen and (max-width: 1200px) {
  .banner_img_section {
    display: none;
  }
  .plane_decoration,
  .content_decoration {
    display: none;
  }
  .banner_img_section {
    background-image: none;
    flex: 0.5 !important;
  }
  .banner_text_section > div:last-child {
    display: none;
  }
  .banner_text_section button {
    /* width: 200px; */
    padding: 1rem 4.2rem;
  }
  .agent_btn {
    bottom: 60px;
    left: 0px !important;
    display: none;
  }
  .banner_btn button:last-child {
    display: inline-flex;
    text-align: center;
    margin-left: 20px;
  }
  .banner_btn {
    column-gap: 20px;
  }
}
@media screen and (max-width: 991px) {
  .home_banner {
    padding: 8px 30px 50px 20px;
  }
  .agent_btn {
    display: none;
  }
  .home_banner .banner_text_section h1 {
    font-size: var(--hero-text-mobile);
  }
  .home_banner .banner_text_section p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 26px;
  }
  .home_banner .banner_text_section h1 span img {
    width: 109px;
  }

  .banner_btn {
    display: flex;
    column-gap: 12px;
    height: 110px;
  }
  .banner_btn button {
    width: 100%;
    padding: 1rem 2rem;
  }
  .banner_btn a {
    width: 100%;
    border-radius: 15px;
    color: white;
    font-family: "Poppins";
    padding: 1rem 2rem;
  }

  .banner_btn button:last-child {
    display: inline-flex;
  }
  .blockchain_text_section {
    flex: 2 !important;
  }
  .blockchain_text_section h1 {
    font-size: 20px;
  }
  .blockchain_text_section p {
    font-size: 14px;
    line-height: 24px; /* 153.846% */
    margin-top: 10px;
  }
  .blockchain_section {
    padding: 50px 30px;
  }

  .partner_images {
    column-gap: 10px;
    padding: 50px 0px;
  }
  .partner_images img {
    width: 30px;
  }
  .banner_trio {
    display: none;
  }
  .blockchain_img_section img {
    width: 180px;
  }
  .faq_flex {
    flex-direction: column;
    row-gap: 30px;
  }
  .faq_section {
    padding: 50px 30px;
  }
  .faq_section h1 {
    font-size: 28px;
  }
  .faq_btn {
    width: 100%;
  }
  .faq_btn button {
    width: 100% !important;
  }

  .globe_text h1 {
    font-size: 20px;
  }
  .globe_text p {
    font-size: 14px;
    padding-top: 10px;
    display: none;
    line-height: 20px; /* 153.846% */
  }
  .globe {
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: 80% 2%;
    padding: 50px 0px 50px 30px;
  }
  .globe_desc svg {
    width: 16px;
  }
  .globe_desc {
    /* position: relative; */
    height: 280px;
  }
  .globe_desc button {
    padding: 0.4rem 0.8rem;
    /* width: 200px; */
    column-gap: 4px;

    font-size: 12px;
    border-radius: 12px;
  }
  .globe_desc button:nth-child(1) {
    top: 30%;
    left: -50%;
  }
  .globe_desc button:nth-child(2) {
    top: 20px;
    right: 20%;
  }
  .globe_desc button:nth-child(3) {
    bottom: 20px;
    left: -50%;
  }

  .globe_delivery_section {
    padding: 20px 0px;
  }
  .delivery_tab {
    border-radius: 20px 0px 0px 0px;
    /* box-shadow: -2px 1px 4px -2px rgba(0, 0, 0, 0.69); */
  }
  .delivery_tab button {
    background: rgba(171, 171, 171, 0.15);
    width: 80.033px;
    padding: 8px;
    align-items: center;
    gap: 3px;
    font-size: 10px;
    border-radius: 0px;
  }
  .delivery_tab button svg {
    width: 14px;
  }
  .delivery_tab button:first-child {
    border-radius: 20px 0px 0px 0px;
    background-color: #fff;
    box-shadow: -2px 0px 2px 0px rgba(0, 0, 0, 0.1);
  }
  .delivery_tab_pannels {
    padding: 12px 14px;
    gap: 10px;
    border-radius: 0px 20px 20px 20px;
  }
  .delivery_tab_pannel {
    column-gap: 10px;
    min-height: 20px;
  }
  .delivery_tab_pannel_divider {
    width: 1px;
    height: 40px;
  }
  .delivery_tab_pannel > div h6 {
    font-size: 10px;
    line-height: 18px;
    /* text-align: center; */
  }
  .delivery_tab_pannel > div:not(:last-child) button {
    font-size: 6px;
    line-height: 12px;
    column-gap: 3px;
    padding: 8px 10px;
    text-align: left;
  }
  .delivery_tab_pannel > div button svg:first-child path {
    color: #db2a6b;
  }
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 400px) {
  .banner_btn a {
    font-size: 11px;
  }
}
